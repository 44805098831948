import { FC, useCallback } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useLanguages from "hooks/useLanguages";
import { useForm } from "react-hook-form";
import useAuth from "hooks/useAuth";
import { getToken } from "firebase/messaging";
import { messaging } from "config/firebase-config";

export interface PageLoginProps {
  className?: string;
}

export type SignInParams = {
  phonenumberOrEmail: string
  password: string
  deviceToken?: string
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { t } = useLanguages();
  const { login, loginLoading } = useAuth({});
  const { register, watch, handleSubmit } = useForm<SignInParams>();

  const onSubmit = useCallback(async (values: SignInParams) => {
    const token = await getToken(messaging, { vapidKey: "BLNsEohMdY3T4rdSTWKVRo0D2WZ2nZsys1yf_YGXv_jJuEmjjTwGnxvXZHHQ25BsPJpmRM9MTU8rYRSB0if4MfU" });
    login({ ...values, deviceToken: token });
  }, []);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>{`${t("pages.login")}`} | Oteller</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('general.log-into-account')}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit(onSubmit)}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('fields.phonenumberOrEmail')}
              </span>
              <Input
                type="text"
                autoComplete="off"
                className="mt-1"
                defaultValue={watch("phonenumberOrEmail")}
                {...register("phonenumberOrEmail", {
                  required: "Required"
                })}
              />
            </label>
            <label className="block relative">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('fields.password')}
                <Link to="/forgot-password" className="text-sm text-primary-6000 font-medium">
                  {t('button.forgot-password')}
                </Link>
              </span>
              <Input
                type="password"
                autoComplete="off"
                className="mt-1"
                defaultValue={watch("password")}
                {...register("password", {
                  required: "Required"
                })}
              />
            </label>
            <ButtonPrimary type="submit" loading={loginLoading}>{t('button.continue')}</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('general.new-user')} {` `}
            <Link to="/sign-up" className="text-primary-6000 font-medium underline">{t("button.sign-up")}</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
