import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAzUrnNDhKwbeMTIcQco7HyGpRvg-tv-L4",
  authDomain: "oteller-de1b1.firebaseapp.com",
  projectId: "oteller-de1b1",
  storageBucket: "oteller-de1b1.firebasestorage.app",
  messagingSenderId: "1009034951467",
  appId: "1:1009034951467:web:ea7357d1bb13b9ab06636e"
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
