import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
// import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import { Toaster } from "react-hot-toast";
import PrivacyPage from "containers/PrivacyPage";
import PartnerPage from "containers/PartnerPage";
import ListingPage from "containers/ListingPage";
import HotelsPage from "containers/HotelsPage";
import ListingRoomPage from "containers/ListingRoomPage";
import AccountGuests from "containers/AccountPage/AccountGuests";
import SearchPage from "containers/SearchPage/SearchPage";
import SearchDetailPage from "containers/SearchDetailPage/SearchDetailPage";
import AccountBookings from "containers/AccountPage/AccountBooking";
import HotelRooms from "containers/MyHotelPage/HotelRooms";
import HotelBookings from "containers/MyHotelPage/HotelBookings";
import UserGuard from "middleware/UserGuard";
import CommonGuard from "middleware/CommonGuard";
import AuthGuard from "middleware/AuthGuard";
import ForgetPasswordPage from "containers/ForgetPasswordPage";


export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/search", component: SearchPage },
  { path: "/search/:id", component: SearchDetailPage },
  { path: "/hotels", component: HotelsPage },
  { path: "/contact-us", component: PageContact },
  { path: "/about-us", component: PageAbout },
  { path: "/become-partner", component: PartnerPage },
  { path: "/privacy-policy", component: PrivacyPage },
  { path: "/forgot-password", component: ForgetPasswordPage }
];

const authRoutes: Page[] = [
  { path: "/sign-up", component: PageSignUp },
  { path: "/sign-in", component: PageLogin }
];

const hostRoutes: Page[] = [
  { path: "/author", component: AuthorPage },
  { path: "/list-your-hotel", component: ListingPage },
  { path: "/my-hotel/:id", component: HotelRooms },
  { path: "/my-hotel/:id/add-room", component: ListingRoomPage },
  { path: "/my-hotel/:id/rooms", component: HotelRooms },
  { path: "/my-hotel/:id/reservations", component: HotelBookings }
];

const commonRoutes: Page[] = [
  { path: "/account", component: AccountPage },
  // { path: "/change-password", component: AccountPass },
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
];

const userRoutes: Page[] = [
  { path: "/guests", component: AccountGuests },
  { path: "/wishlist", component: AccountSavelists },
  { path: "/bookings", component: AccountBookings },
]

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />
        })}
        <Route element={<AuthGuard />}>
          {authRoutes.map(({ component, path }) => {
            const Component = component;

            return <Route key={path} element={<Component />} path={path} />
          })}
        </Route>

        <Route element={<CommonGuard />}>
          <Route element={<UserGuard role={"host"} href={"/become-partner"} />}>
            {hostRoutes.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />
            })}
          </Route>
          <Route element={<UserGuard role={"user"} />}>
            {userRoutes.map(({ component, path }) => {
              const Component = component;
              return <Route key={path} element={<Component />} path={path} />
            })}
          </Route>
          {commonRoutes.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />
          })}
        </Route>
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
      <Toaster position={"bottom-right"}></Toaster>
    </BrowserRouter>
  );
};

export default MyRoutes;
