import { messaging } from "config/firebase-config";
import { onMessage } from "firebase/messaging";
import firebaseRequestPermission from "middleware/firebase-permission";
import { useEffect } from "react";
import toast from "react-hot-toast";
import MyRouter from "routers/index";

function App() {
  useEffect(() => {
    firebaseRequestPermission();
  }, []);

  onMessage(messaging, (payload) => {
    toast.success(payload.notification?.title || "New notification");
  });

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
