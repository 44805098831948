import { CalendarIcon, DocumentArrowDownIcon, UsersIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "api";
import useConvertDates from "hooks/useConvertDates";
import useLanguages from "hooks/useLanguages";
import moment from "moment";
import { FC } from "react";
import Badge from "shared/Badge/Badge";
import Logo from "shared/Logo/Logo";

const BookingCard: FC<IBooking> = ({ _id, hotelId, status, dates, roomType, roomQuantity, guests, price }) => {
  const { t, i18n } = useLanguages();
  const queryClient = useQueryClient();
  const startDate = dates[0];
  const endDate = dates[dates?.length - 1];
  const checkIn = useConvertDates(startDate);
  const checkOut = useConvertDates(endDate);
  const days = moment(endDate).diff(startDate, "days");
  const { mutate } = useMutation({
    mutationKey: ["cancel-booking", _id],
    mutationFn: async (id: string) => {
      const res = await api.put(`/bookings/${id}`, { status: "cancelled" });
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bookings"]});
    }
  });

  return (
    <div className="group space-y-5 p-4 border rounded-2xl border-neutral-200 dark:border-neutral-700">
      <div className="flex justify-between items-start">
        <div>
          <h2 className="text-xl font-semibold">{hotelId?.title[i18n.language as keyof ITranslation]}</h2>
          <p className='text-md font-medium text-neutral-500 dark:text-neutral-400'>{hotelId?.phonenumber}</p>
        </div>
        <Badge className='capitalize' name={t(`status.${status}`)} color={status === "pending" ? "blue" : status === "booked" ? "green" : "red"} />
      </div>
      <div className="w-full flex flex-col sm:flex-row divide-y-[1px] divide-neutral-200 dark:divide-neutral-700 sm:divide-y-0 space-y-3 sm:space-y-0 sm:divide-x">
        <div className="flex-1 flex sm:divide-x">
          <div className="px-2 flex-1">
            <span className="inline-flex space-x-1">
              <CalendarIcon className="w-4 h-4 text-primary-6000" />
              <p className='text-sm text-neutral-500 dark:text-neutral-400'>{t('fields.check-in')}</p>
            </span>
            <p className='text-base font-medium'>{checkIn.format("DD.MM.YYYY")}</p>
          </div>
          <div className='px-2 flex-1'>
            <span className='inline-flex space-x-1'>
              <CalendarIcon className='w-4 h-4 text-primary-6000' />
              <p className='text-sm text-neutral-500 dark:text-neutral-400'>{t('fields.check-out')}</p>
            </span>
            <p className='text-base font-medium'>{checkOut.format("DD.MM.YYYY")}</p>
          </div>
        </div>
        <div className="flex-1 flex pt-3 sm:pt-0 sm:divide-x">
          <div className="px-2 flex-1">
            <span className="inline-flex space-x-1">
              <CalendarIcon className="w-4 h-4 text-primary-6000" />
              <p className='text-sm text-neutral-500 dark:text-neutral-400'>{t('fields.room')}</p>
            </span>
            <p className='text-base font-medium'>{roomType} ({roomQuantity})</p>
          </div>
          <div className='px-2 flex-1'>
            <span className='inline-flex space-x-1'>
              <UsersIcon className='w-4 h-4 text-primary-6000' />
              <p className='text-sm text-neutral-500 dark:text-neutral-400'>{t('fields.guests')}</p>
            </span>
            <p className='text-base font-medium'>{guests?.length}</p>
          </div>
        </div>
      </div>
      <div className='w-full border-b border-neutral-200 dark:border-neutral-700'></div>
      <div className="space-y-2">
        <h2 className="text-right">{t('checkout.total')}: <span className='text-lg text-primary-6000 font-semibold'>{price * days} TMT</span></h2>
        <div className="flex justify-between items-center">
          <Logo className="w-20" />
          <div className="inline-flex items-center space-x-1">
            {status === "booked" && <a href={`/api/v1/bookings/${_id}/pdf?lang=${i18n.language}`} download className='px-2 py-1 inline-flex items-center rounded-full text-white bg-primary-6000 hover:bg-primary-700 transition-colors'>
              <DocumentArrowDownIcon className='w-4 h-4' />
              <span className='pl-1'>{t('button.download')}</span>
            </a>}
            {status === "pending" ? <button className='px-2 py-1 inline-flex items-center rounded-full text-white bg-red-500 hover:bg-red-600 transition-colors' onClick={() => mutate(_id)}>
              <XMarkIcon className='w-4 h-4' />
              <span className='pl-1'>{t('button.cancel')}</span>
            </button> : null}
          </div>
        </div>
      </div>
    </div>
  )
};

export default BookingCard;