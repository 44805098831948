import { getToken } from 'firebase/messaging';
import { messaging } from '../config/firebase-config';

export default async function firebaseRequestPermission() {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    await getToken(messaging, {
      vapidKey: "BLNsEohMdY3T4rdSTWKVRo0D2WZ2nZsys1yf_YGXv_jJuEmjjTwGnxvXZHHQ25BsPJpmRM9MTU8rYRSB0if4MfU",
    });
  } else if (permission === "denied") {
    console.error("You denied for the notification");
  } else {
    console.error("Notification permission not granted.");
  }
}